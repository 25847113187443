<template>
  <b-overlay :show="show" rounded="sm">
    <div class="bg-light min-vh-100 d-flex flex-row align-items-center">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-1"></div>
          <div class="col-md-3">
            <div>
              <div>
                <div class="card-body">
                  <CForm>
                    <h1 class="font-weight-bolder text-info text-gradient">
                      {{ $t('system.name') }}
                    </h1>
                    <br />
                    <div class="mb-3">
                      <label for="email">系統用戶</label>
                      <div class="border rounded-1">
                        <input
                          v-model="login_name"
                          type="text"
                          class="form-control"
                        />
                      </div>
                      <span
                        v-if="login_name_missing"
                        class="font-weight-bolder text-danger text-gradient"
                        >Missing Login</span
                      >
                    </div>
                    <div class="mb-3">
                      <label for="email">密碼</label>
                      <div class="border rounded-1">
                        <input
                          v-model="password"
                          type="password"
                          class="form-control"
                        />
                      </div>
                      <span
                        v-if="password_missing"
                        class="font-weight-bolder text-danger text-gradient"
                        >Missing Password</span
                      >
                    </div>
                    <div class="row justify-content-center">
                      <div class="col-xs-6">
                        <button
                          type="button"
                          class="btn bg-gradient-info w-100 mt-4 mb-0"
                          @click="login"
                        >
                          Login
                        </button>
                        <span
                          v-if="login_fail"
                          class="font-weight-bolder text-danger text-gradient"
                          >登入失敗</span
                        >
                      </div>
                    </div>
                  </CForm>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-5">
            <div class="oblique position-absolute d-md-block d-none me-n8">
              <CAvatar
                :src="avatar"
                class="text-white py-5"
                style="width: 100%"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import avatar from '@/assets/images/ankh.jpg'

export default {
  name: 'Login',
  data() {
    return {
      show: false,
      avatar: avatar,
      login_name: '',
      login_name_missing: false,
      password: '',
      password_missing: false,
      login_fail: false,
    }
  },
  methods: {
    login() {
      var self = this
      self.login_name_missing = false
      self.password_missing = false
      self.login_fail = false
      if (!self.login_name) {
        self.login_name_missing = true
      }
      if (!self.password) {
        self.password_missing = true
      }
      if (self.login_name && self.password) {
        self.show = true
        this.axios
          .post('auth/login', {
            staff_code: self.login_name,
            password: self.password,
            remember_me: true,
          })
          .then(function (response) {
            var auth_object = {
              access_token: response.data.access_token,
              expires_at: response.data.expires_at,
              permission: response.data.permission,
            }
            localStorage.setItem('auth_object', JSON.stringify(auth_object))
            var uagent = navigator.userAgent.toLowerCase()
            self.show = false
            if(/safari/.test(uagent) && !/chrome/.test(uagent)){
              var url = 'http://' + location.host + '/#/Dashboard'
              window.location.replace(url)
            }else{
              self.$router.push('/')
            }
          })
          .catch(function (error) {
            console.log(error)
            self.login_fail = true
            self.show = false
          })
      }
    },
  },
}
</script>
